import Link from 'next/link';
import {
  Typography,
  Space,
  Button,
  Empty,
} from 'antd';

import styles from '../styles/Home.module.css';

const { Title, Text } = Typography;

export default function Home() {
  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <Space size="middle" direction="vertical" align="center">
          <Title>
            404
          </Title>

          <Empty
            description={
              <Text>
                The page you are looking for does not exist.
              </Text>
            }
          >
            <Button type="primary">
              <Link href="/">
                Go to Homepage
              </Link>
            </Button>
          </Empty>
        </Space>
      </main>
    </div>
  );
}
